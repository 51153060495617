import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { BlockChainType, BlockChainTypeToString } from "../types/blockChain";
import { Collection } from "../types/collection";
import { INFT } from "../types/iNft";
import { getDisplayPriceNumber, getDisplayPriceType } from "../utils/util";

interface Props {
  iNFT: INFT,
}

const CreateName = styled('p')(() => ({
  color: '#898989',
  fontsize: '12px',
  fontFamily: 'ultraRegular',
  padding: '0',
  margin: '0',
  marginBottom: '6px',
}));

const CollectionName = styled('p')(() => ({
  color: '#FFFFFF',
  fontsize: '12px',
  fontFamily: 'ultraRegular',
  padding: '0',
  margin: '0',
  marginBottom: '20px',
  lineHeight: '20px',
  height: '20px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const PriceType = styled('span')(() => ({
  color: '#898989',
  fontsize: '12px',
  fontFamily: 'ultraRegular',
}));

const Price = styled('span')(() => ({
  color: '#FFFFFF',
  fontsize: '14px',
  fontFamily: 'ultraRegular',
}));

const PriceBold = styled('strong')(() => ({
  color: '#FFFFFF',
  fontSize: '20px',
  fontFamily: 'ultraRegular',
  marginRight: '8px',
}));

const MarketplaceItem = ({ iNFT }: Props): JSX.Element => {
  const collectionList = useSelector<RootState, Collection[]>((state) => state.collection.list);
  const chainType = useSelector<RootState, BlockChainType>((state) => state.system.chainType);
  const collection = collectionList.find((item: Collection) => item.id === iNFT.colloctionId);

  return (
    <Box
      sx={{
        width: {
          sm: 120,
          lg: 260,
          xl: 340,
        },
        height: {
          sm: 240,
          lg: 320,
          xl: 456,
        },
        color: '#FFF',
        borderRadius: '16px',
        border: '1px solid #383737',
        backgroundColor: '#151515',
        marginBottom: '12px',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <img
          src={iNFT.iNftImage.url}
          alt=""
          style={{
            width: '100%',
            height: '323px',
          }}
        />
        <Box
          sx={{ padding: '17px 15px 0 15px' }}
        >
          <CreateName>
            {collection?.creatorName}
          </CreateName>
          <CollectionName>
            {collection?.name}
          </CollectionName>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            {
              getDisplayPriceType(iNFT) ? <PriceType>
                {getDisplayPriceType(iNFT)}
              </PriceType> : <span></span>
            }
            {
              getDisplayPriceNumber(iNFT, chainType) !== null && getDisplayPriceNumber(iNFT, chainType) !== 0 ? <Price>
                <PriceBold>
                  {Math.ceil((getDisplayPriceNumber(iNFT, chainType) ?? 0) * 100) / 100}
                </PriceBold>
                {BlockChainTypeToString(chainType)}
              </Price> : <span></span>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default MarketplaceItem;
