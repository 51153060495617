export enum BlockChainType {
  NXD,
  BTC,
  ETH,
}

export const BlockChainTypeToString = (type: BlockChainType): string => {
  let string = 'NXD';
  switch (type) {
    case BlockChainType.NXD:
      string = 'NXD'
      break;
    case BlockChainType.BTC:
      string = 'BTC'
      break;
    case BlockChainType.ETH:
      string = 'ETH'
      break;
    default:
      break;
  }
  return string;
}
