import { Button, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Collection, TransactionStatus } from "../types/collection";
import { useHistory } from 'react-router-dom';

import NXDImage from '../assets/NXD_selected.png';
import BTCImage from '../assets/BTC_selected.png';
import ETHImage from '../assets/ETH_selected.png';
import NewImage from '../assets/new.png';

import { useSelector } from "react-redux";
import { RootState } from "../store";
import { BlockChainType } from "../types/blockChain";

interface BlandBoxProps {
  collection: Collection,
}

const BlandCreator = styled(Typography)(() => ({
  fontSize: 18,
  fontFamily: 'ultraRegular',
  lineHeifght: 25,
}));

const BlandName = styled(Typography)(() => ({
  fontSize: 13,
  fontFamily: 'ultraRegular',
  lineHeifght: 20,
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginBottom: '12px',
}));

const BuyButton = styled(Button)(() => ({
  width: 152,
  height: 36,
  backgroundColor: '#20FB4B',
  color: '#000000',
  fontSize: '12px',
  borderRadius: '100px',
  textTransform: 'none',
  fontFamily: 'ultraRegular',
  '&:hover': {
    backgroundColor: '#20FB4B',
  }
}));

const BlandBox = ({ collection }: BlandBoxProps): JSX.Element => {
  const chainType = useSelector<RootState, BlockChainType>((state) => state.system.chainType);
  const history = useHistory();

  const handleClickBlandItem = (id: string) => {
    console.log('点击了');
    history.push(`/collection/${id}`);
  }

  return (
    <>
      <Box
        sx={{ width: 300, height: 360, margin: '0 20px 50px', display: 'flex', flexDirection: 'column', cursor: 'pointer', backgroundColor: 'transparent' }}
        onClick={() => { handleClickBlandItem(collection.id) }}
      >
        <Box
          sx={{ width: '100%', height: 240, position: 'relative', }}
        >
          <img src={collection.blandBoxImage.url} style={{ width: '100%', height: '100%' }} alt="" />
          {
            Date.now() - collection.createTime <= 1000 * 60 * 60 * 24 * 30 && collection.transctionStatus !== TransactionStatus.saleOut && (<Box
              sx={{
                width: 147,
                height: 149,
                position: 'absolute',
                left: '-1px',
                top: '-1px',
              }}
            >
              <img src={NewImage} alt="new" style={{ width: '100%', height: '100%', display: 'block' }} />
            </Box>)
          }
          {
            collection.transctionStatus === TransactionStatus.saleOut && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.4)',
                }}
              >
                <Box
                  sx={{
                    width: 159,
                    height: 40,
                    fontSize: 14,
                    borderRadius: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    color: '#FFFFFF',
                    fontFamily: 'ultraBold',
                    letterSpacing: '2px',
                  }}
                >
                  SOLD OUT
                </Box>
              </Box>
            )
          }
        </Box>
        <Box sx={{ flexGrow: 1 }}></Box>
        <Box sx={{ width: '100%', paddingLeft: '40px', paddingRight: '40px' }}>
          <BlandCreator>
            {collection.creatorName}
          </BlandCreator>
          <BlandName>
            {collection.name}
          </BlandName>
          <BuyButton>
            <img
              style={{ marginTop: '-2px' }}
              src={chainType === BlockChainType.NXD ? NXDImage : chainType === BlockChainType.BTC ? BTCImage : ETHImage}
              width={24}
              height={24}
              alt=""
            />
            <span style={{ fontSize: '14px' }}>
              {chainType === BlockChainType.NXD ? Math.ceil(collection.startingPrice.NXD * 100) / 100 : chainType === BlockChainType.BTC ? Math.ceil(collection.startingPrice.BTC * 100) / 100 : Math.ceil(collection.startingPrice.ETH * 100) / 100}
            </span>
            &nbsp;
            {chainType === BlockChainType.NXD ? 'NXD' : chainType === BlockChainType.BTC ? 'BTC' : 'ETH'}
          </BuyButton>
        </Box>
      </Box>
    </>
  );
}

export default BlandBox;
