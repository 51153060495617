import { AppBar, Button, Link, TextField, Toolbar, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import Bottombar from "../components/bottombar";
import { useHistory } from 'react-router-dom';
import logo from '../logo.png';

const FormTitle = styled('div')(() => ({
  fontSize: '16px',
  color: '#FFFFFF',
  fontFamily: 'ultraRegular',
  marginBottom: '12px',
}));

const FormInput = styled(TextField)(() => ({
  '& label.Mui-focused': {
    color: '#20FB4B',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#20FB4B',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'ultraRegular',
    color: '#FFFFFF',
    borderRadius: '16px',
    '& fieldset': {
      borderColor: '#20FB4B',
    },
    '&:hover fieldset': {
      borderColor: '#20FB4B',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#20FB4B',
      borderWidth: '1px',
    },
  },
}));

const RegisterButton = styled(Button)(() => ({
  width: '100%',
  height: 60,
  backgroundColor: '#20FB4B',
  color: '#000000',
  fontSize: '20px',
  fontWeight: 'bolder',
  borderRadius: '100px',
  textTransform: 'none',
  fontFamily: 'ultraRegular',
  marginTop: '177px',
  '&:hover': {
    backgroundColor: '#20FB4B',
  }
}));

const Tips = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '12px',
  color: '#A6A6A6',
  lineHeight: '30px',
  fontFamily: 'ultraRegular',
  marginTop: '13px',
}));

const LoginText = styled(Link)(() => ({
  color: '#A6A6A6',
  textDecorationColor: '#A6A6A6',
  cursor: 'pointer',
}));

const Register = (): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Box sx={{
        width: '100%', height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column'
      }}>
        <AppBar position="static" sx={{ backgroundColor: '#272727' }}>
          <Toolbar>
            <img src={logo} height={40} alt="boo universe logo" />
          </Toolbar>
        </AppBar>
        <Box sx={{
          flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}>
          <Box sx={{ width: 520 }}>
            <FormTitle>
              Email
            </FormTitle>
            <FormInput fullWidth />
            <Tips>
              请告诉我们你的邮箱，我们会尽快为你处理注册，成功后会以邮件的方式告知，请注意查收邮件。
            </Tips>
            <RegisterButton>
              Register
            </RegisterButton>
            <Tips sx={{ marginTop: '13px' }}>
              Already have an account?&nbsp;
              <LoginText
                underline="always"
                onClick={() => {
                  history.replace('/login');
                }}
              >
                Login here
              </LoginText>
            </Tips>
          </Box>
        </Box>
        <Bottombar />
      </Box>
    </>
  );
}

export default Register;
