import { Tab, Tabs } from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import OutContainer from "../components/outContainer";
import { TopCollectionsTable } from "../components/table";
import { RootState } from "../store";
import { Collection } from "../types/collection";

interface StyledTabProps {
  label: string;
}

const RankTabs = styled(Tabs)(() => ({
  borderBottom: 'none',
  '.MuiTabs-indicator': {
    width: 0,
    height: 0,
    display: 'none',
  }
}));

const RankTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontSize: '16px',
  fontFamily: 'ultraRegular',
  borderBottom: 'none',
  padding: theme.spacing(0),
  marginRight: theme.spacing(8),
  [(theme.breakpoints.up('sm'))]: {
    minWidth: 0,
  },
  '&.Mui-selected': {
    borderBottom: 'none',
    color: '#FFFFFF',
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const RankTabPanel = (props: TabPanelProps) => {
  const { children, index, value, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: '20px', paddingBottom: '20px' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Ranking = (): JSX.Element => {
  const collections = useSelector<RootState, Collection[]>((state) => state.collection.list);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }

  return (
    <>
      <Box>
        <OutContainer>
          <Box>
            <RankTabs value={tabValue} onChange={handleTabChange}>
              <RankTab label="Top Collections" />
              <RankTab label="Top Creators" />
            </RankTabs>
            <RankTabPanel value={tabValue} index={0}>
              <TopCollectionsTable collections={collections} />
            </RankTabPanel>
            <RankTabPanel value={tabValue} index={1}>
              creators
            </RankTabPanel>
          </Box>
        </OutContainer>
      </Box>
    </>
  );
};

export default Ranking;
