import { BlockChainType } from "../types/blockChain";
import { INFT, Offers, SaleType } from "../types/iNft";

export const sleep = async (time: number): Promise<boolean> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
}

export const getDisplayPriceType = (iNFT: INFT): string | null => {
  if (iNFT.saleType === null) {
    if (!iNFT.offers.length) return null;
    return 'Last';
  } else if (iNFT.saleType === SaleType.fixedPrice) {
    return 'Fixed Price';
  } else if (iNFT.saleType === SaleType.auction) {
    if (!iNFT.offers.length) {
      return 'Min Bid';
    }
    return 'Top Bid';
  } else {
    return null;
  }
}

export const getDisplayPriceNumber = (iNFT: INFT, type: BlockChainType): number | null => {
  if (iNFT.saleType === null) {
    if (!iNFT.offers.length) return null;

    const lastOffer: Offers = iNFT.offers[0];
    return type === BlockChainType.NXD ? lastOffer.price.NXD : type === BlockChainType.BTC ? lastOffer.price.BTC : lastOffer.price.ETH;
  } else if (iNFT.saleType === SaleType.fixedPrice) {
    return type === BlockChainType.NXD ? (iNFT.fixedPrice?.NXD ?? 0) : type === BlockChainType.BTC ? (iNFT.fixedPrice?.BTC ?? 0) : (iNFT.fixedPrice?.ETH ?? 0);
  } else if (iNFT.saleType === SaleType.auction) {
    if (!iNFT.offers.length) {
      return type === BlockChainType.NXD ? (iNFT.auctionMinPrice?.NXD ?? 0) : type === BlockChainType.BTC ? (iNFT.auctionMinPrice?.BTC ?? 0) : (iNFT.auctionMinPrice?.ETH ?? 0);
    }

    const notExpirationOffers = iNFT.offers.filter((item: Offers) => item);
    const sortedOffers: Offers[] = notExpirationOffers.sort((a: Offers, b: Offers) => b.price.NXD - a.price.NXD);
    return type === BlockChainType.NXD ? (sortedOffers[0].price.NXD) : type === BlockChainType.BTC ? (sortedOffers[0].price.BTC) : (sortedOffers[0].price.ETH);
  } else {
    return null;
  }
}
