enum UrlType {
  photo,
  video,
}

export interface ImageType {
  type: UrlType,
  url: string,
}

export const stringToUrlType = (type: string): UrlType => {
  let urlType: UrlType = UrlType.photo;
  if (type === 'video') {
    urlType = UrlType.video;
  }
  return urlType;
}

export const urlTypeToString = (type: UrlType): string => {
  let urlType = 'photo';
  if (type === UrlType.video) {
    urlType = UrlType.video.toString();
  }
  return urlType;
}
