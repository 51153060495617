import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Collection } from '../types/collection';
import { BuyCollectionActionPayload, InitialStateInterface } from '../types/collectionSlice';
import * as _ from 'lodash';

const collectionList: InitialStateInterface = {
  list: [],
};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState: collectionList,
  reducers: {
    addCollectionsData: (state, action: PayloadAction<Collection[]>) => {
      state.list = action.payload;
    },
    // 购买盲盒
    buyCollection: (state, action: PayloadAction<BuyCollectionActionPayload>) => {
      const collectionId: string = action.payload.collectionId;
      const collectionIndex: number = state.list.findIndex((item: Collection) => item.id === collectionId);
      const collection = _.cloneDeep(state.list[collectionIndex]);
      collection.lastCount = collection.lastCount - 1;
      const addNewOwners = collection.owners;
      addNewOwners.push({
        id: action.payload.accountId,
        name: action.payload.accountName,
      });
      collection.owners = Array.from(new Set(addNewOwners));
      state.list[collectionIndex] = collection;
    },
  },
});

export const { addCollectionsData, buyCollection } = collectionsSlice.actions;

// 获取该盲盒下owner的数量
export const getCollectionOwnerCount = (state: RootState, collectionId: string): number => {
  const collection = state.collection.list.find((item: Collection) => item.id === collectionId);
  if (!collection) return 0;
  return collection.owners.length;
}

// 获取盲盒的排行榜
export const getTopCollectionList = (state: RootState): Collection[] => {
  const list = state.collection.list;
  return list.sort((a: Collection, b: Collection) => b.volume - a.volume);
}

export default collectionsSlice.reducer;
