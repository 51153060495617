import { ChainInfo } from "./chainInfo";
import { ImageType, stringToUrlType } from "./image";
import { getPrice, Price } from "./price";

export enum TransactionStatus {
  sale,
  saleOut,
  commingSoon,
}

export const stringToTransactionStatus = (string: string): TransactionStatus => {
  let status: TransactionStatus = TransactionStatus.commingSoon;
  switch (string) {
    case 'sale':
      status = TransactionStatus.sale;
      break;
    case 'saleOut':
      status = TransactionStatus.saleOut;
      break;
    case 'commingSoon':
      status = TransactionStatus.commingSoon;
      break;
    default:
      break;
  }
  return status;
}

interface PropertyDistribution {
  id: string, // ID
  icon: string, // 图标
  name: string, // 名称
  rarity: string, // 稀有度
  issueAmount: number, // 发行数量
  probability: number, // 概率
}

const getPropertyDistribution = (data: any): PropertyDistribution[] => {
  const props: PropertyDistribution[] = [];
  data.forEach((item: any) => {
    const prop: PropertyDistribution = {
      id: item.id,
      icon: item.icon,
      name: item.name,
      rarity: item.rarity,
      issueAmount: item.issueAmount,
      probability: item.probability,
    }
    props.push(prop);
  });
  return props;
}

interface Owners {
  id: string,
  name: string,
}

// 盲盒
export interface Collection {
  id: string, // 盲盒ID
  name: string, // 盲盒名称
  createTime: number, // 创建盲盒的时间戳
  creatorId: string, // 创建人ID
  creatorName: string, // 创建人昵称
  totalCount: number, // 盲盒发型总数
  lastCount: number, // 盲盒剩余数量
  startingPrice: Price, // 发售价
  description: string, // 盲盒简介
  blandBoxImage: ImageType, // 盲盒封面图
  avatar: string, // 头像
  banner: string, // 背景图
  preview: ImageType[], // 预览图
  onChainInfo: ChainInfo, // 区块链信息
  transctionStatus: TransactionStatus, // 交易状态
  content: PropertyDistribution[], // 属性分配
  owners: Owners[], // 拥有者ID的集合
  volume: number, // 总数
  floorPrice: Price, // 地板价
}


export const jsonToCollection = (json: any): Collection => {
  function getImagePreview(data: any): ImageType[] {
    const previews: ImageType[] = [];
    if (data && data.length > 0) {
      data.forEach((item: any) => {
        previews.push({
          type: stringToUrlType(item.type),
          url: item.url,
        });
      });
    }
    return previews;
  }

  function getOwners(json: any): Owners[] {
    const owners: Owners[] = [];
    if (json && json.length > 0) {
      json.forEach((item: any) => {
        owners.push({
          id: item.id,
          name: item.name,
        });
      });
    }
    return owners;
  }


  const collection: Collection = {
    id: json.id, // 盲盒ID
    name: json.name, // 盲盒名称
    createTime: json.createTime, // 创建盲盒的时间戳
    creatorId: json.creatorId, // 创建人ID
    creatorName: json.creatorName, // 创建人昵称
    totalCount: json.totalCount, // 盲盒发型总数
    lastCount: json.lastCount, // 盲盒剩余数量
    startingPrice: getPrice(json.startingPrice), // 发售价
    description: json.description, // 盲盒简介
    blandBoxImage: {
      type: stringToUrlType(json.blandBoxImage.type),
      url: json.blandBoxImage.url,
    }, // 盲盒封面图
    avatar: json.avatar, // 头像
    banner: json.banner, // 背景图
    preview: getImagePreview(json.preview), // 预览图
    onChainInfo: {
      contractAddress: json.onChainInfo.contractAddress,
      tokenId: json.onChainInfo.tokenId,
      blockChain: json.onChainInfo.blockChain,
    }, // 区块链信息
    transctionStatus: stringToTransactionStatus(json.transctionStatus), // 交易状态
    content: getPropertyDistribution(json.content), // 属性分配
    owners: getOwners(json.owners), // 拥有者ID的集合
    volume: json.volume, // 总数
    floorPrice: getPrice(json.floorPrice), // 地板价
  };
  return collection;
}
