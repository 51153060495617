import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { BlockChainType } from "../types/blockChain";
import { InitialStateInterface } from "../types/loadingSlice";

const initialState = {
  isLoading: false,
  chainType: BlockChainType.NXD,
} as InitialStateInterface;

export const loadingStatusSlice = createSlice({
  name: 'loadingStatus',
  initialState,
  reducers: {
    openLoading: (state) => {
      state.isLoading = true;
    },
    closeLoading: (state) => {
      state.isLoading = false;
    },
    changeChainType: (state, action: PayloadAction<BlockChainType>) => {
      state.chainType = action.payload;
    }
  },
});

export const { openLoading, closeLoading, changeChainType } = loadingStatusSlice.actions;

export const getLoadingStatus = (state: RootState): boolean => state.system.isLoading;

export default loadingStatusSlice.reducer;
