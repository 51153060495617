import { Collection, jsonToCollection } from "../types/collection";
import { httpGet } from "../utils/axios";

export const getCollections = async (): Promise<Collection[]> => {
  try {
    const data: any = await httpGet('/datas/collections.json', {}, false);
    const collections: Collection[] = [];
    data.forEach((item: any) => {
      const collection: Collection = jsonToCollection(item);
      collections.push(collection);
    });
    return collections;
  } catch (error) {
    throw error;
  }
}
