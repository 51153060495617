import Web3 from 'web3';

type networkChangeCallback = () => void;

export class NxWeb3 {
  private static _ins: NxWeb3;
  static get instance(): NxWeb3 {
    return this._ins || (this._ins = new NxWeb3());
  }

  // @ts-ignore
  web3: Web3;

  isInited: boolean = false;

  accountAddress: string = '';

  balance: number = 0;

  get ethBalance(): number {
    return this.dether(this.balance);
  }

  // 合约地址
  contractAddress: string = '';

  // 合约
  contractAbi: Object[] = [];

  constructor() {
    console.log('init');
  }

  async takeConstructor(callback: networkChangeCallback) {
    this.isInited = await this.init();
    if (this.isInited) {
      await this.connect();
      this.listenNetworkChange(callback);
      this.listenAccountChange();
    }
  }

  async init(): Promise<boolean> {
    try {
      // @ts-ignore
      if (!ethereum || !ethereum.isMetaMask) {
        alert('plase install MetaMask.');
        return false;
      }
      // @ts-ignore
      if (!window.web3) {
        alert('MetaMask not installed in your browser.');
        return false;
      }

      // @ts-ignore
      this.web3 = new Web3(window.ethereum);

      try {
        // @ts-ignore
        await window.ethereum.request({ method: 'eth_requestAccounts' });
      } catch (error) {
        // @ts-ignore
        if (error && error.code === 4001) {
          return false;
        }
      }
      return true;
    } catch (error) {
      alert('MetaMask not install in your browser.');
      return false;
    }
  }

  async getChainId(): Promise<number> {
    const id: number = await this.web3.eth.getChainId();
    return id;
  }

  async connect() {
    try {
      // @ts-ignore
      const rst = await this.web3.eth.getAccounts();
      this.accountAddress = rst[0];

      this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
    } catch (error) {
      console.log(error);
    }
  }

  listenAccountChange() {
    if (this.isInited) {
      // @ts-ignore
      window.ethereum.on('accountsChanged', async (accounts) => {
        this.accountAddress = this.web3.utils.toChecksumAddress(accounts[0]);
        this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
      });
    }
  }

  listenNetworkChange(callback: networkChangeCallback) {
    if (this.isInited) {
      // @ts-ignore
      window.ethereum.on('chainChanged', async (_) => {
        const rst = await this.web3.eth.getAccounts();
        this.accountAddress = this.web3.utils.toChecksumAddress(rst[0]);

        this.balance = parseFloat(await this.web3.eth.getBalance(this.accountAddress));
        callback && callback();
      });
    }
  }

  ether(eth: Number): number {
    return parseInt(this.web3.utils.toWei(eth.toString()), 10);
  }

  dether(eth: number): number {
    return parseFloat((eth / 1000000000000000000).toFixed(2));
  }
}

export const linkWallet = async (): Promise<boolean> => {
  // 如果没有链接钱包，就去链接钱包
  if (!NxWeb3.instance.isInited) {
    await NxWeb3.instance.takeConstructor(() => { });
  }

  if (!NxWeb3.instance.isInited) {
    return false;
  }

  return true;
}

export const isMianChain = async (): Promise<boolean> => {
  const chainId = await NxWeb3.instance.getChainId();
  if (chainId !== 4) {
    return false;
  }
  return true;
}
