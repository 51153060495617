import { Container, ContainerProps } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ReactNode } from "react";

const ContainerComponent = styled(Container)<ContainerProps>(({ theme }) => ({
  minHeight: '100vh',
  padding: 0,
  paddingTop: 20,
  [theme.breakpoints.up('md')]: {
    padding: 0,
    paddingTop: 20,
  }
}));

interface Props {
  children: ReactNode,
}

const OutContainer = ({ children }: Props): JSX.Element => {
  return (
    <>
      <ContainerComponent
        maxWidth="xl"
      >
        {children}
      </ContainerComponent>
    </>
  );
}

export default OutContainer;
