import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DisplayPrice, INFT, Offers, SaleType, TradeInfo } from "../types/iNft";
import { AddTradeRecordPayloadAction, InitialStateInterface, MakeOfferPayloadAction, SetSalePricePayloadAction } from "../types/iNftSlice";
import * as _ from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { RootState } from "../store";


const iNFTList: InitialStateInterface = {
  list: [],
};

export const iNFTSlice = createSlice({
  name: 'iNFT',
  initialState: iNFTList,
  reducers: {
    addINFTs: (state, action: PayloadAction<INFT[]>) => {
      state.list = action.payload;
    },
    setFixedPrice: (state, action: PayloadAction<SetSalePricePayloadAction>) => {
      const iNftIndex: number = state.list.findIndex((item: INFT) => item.id === action.payload.iNftId);
      const iNft: INFT = _.cloneDeep(state.list[iNftIndex]);
      iNft.saleType = SaleType.fixedPrice;
      iNft.fixedPrice = action.payload.price;
      state.list[iNftIndex] = iNft;
    },
    setAuction: (state, action: PayloadAction<SetSalePricePayloadAction>) => {
      const iNftIndex: number = state.list.findIndex((item: INFT) => item.id === action.payload.iNftId);
      const iNft: INFT = _.cloneDeep(state.list[iNftIndex]);
      const deadLine: number = Date.now() + 1000 * 60 * 60 * 24 * 2;
      iNft.saleType = SaleType.auction;
      iNft.auctionMinPrice = action.payload.price;
      iNft.auctionDeadLine = deadLine;
      state.list[iNftIndex] = iNft;
    },
    makeOffer: (state, action: PayloadAction<MakeOfferPayloadAction>) => {
      const iNftIndex: number = state.list.findIndex((item: INFT) => item.id === action.payload.iNftId);
      const iNft: INFT = _.cloneDeep(state.list[iNftIndex]);
      const offer: Offers = {
        id: uuidV4(),
        iNftId: action.payload.iNftId,
        expiration: 0,
        fromId: action.payload.accountId,
        fromName: action.payload.accountName,
        price: action.payload.price,
      };
      iNft.offers.unshift(offer);
      state.list[iNftIndex] = iNft;
    },
    addTradeRecord: (state, action: PayloadAction<AddTradeRecordPayloadAction>) => {
      const iNftIndex: number = state.list.findIndex((item: INFT) => item.id === action.payload.iNftId);
      const iNft: INFT = _.cloneDeep(state.list[iNftIndex]);
      const trade: TradeInfo = {
        id: uuidV4(),
        time: Date.now(),
        buyId: action.payload.buyUserId,
        buyName: action.payload.buyUserName,
        sellId: action.payload.sellUserId,
        sellName: action.payload.sellUserName,
        price: action.payload.price,
      };
      iNft.trades.unshift(trade);
      state.list[iNftIndex] = iNft;
    },
  },
});

export const { addINFTs, setFixedPrice, setAuction, makeOffer } = iNFTSlice.actions;

export const getDisplayPrice = (state: RootState, iNftId: string): DisplayPrice | null => {
  const iNftIndex: number = state.iNFT.list.findIndex((item: INFT) => item.id === iNftId);
  const iNft: INFT = _.cloneDeep(state.iNFT.list[iNftIndex]);
  if (iNft.saleType === null) {
    if (!iNft.offers.length) return null;

    const lastOffer: Offers = iNft.offers[0];
    return {
      displayContent: 'Last',
      price: lastOffer.price.NXD,
    };
  } else if (iNft.saleType === SaleType.fixedPrice) {
    return {
      displayContent: 'Fixed Price',
      price: iNft.fixedPrice?.NXD ?? 0,
    }
  } else if (iNft.saleType === SaleType.auction) {
    if (!iNft.offers.length) {
      return {
        displayContent: 'Min Bid',
        price: iNft.auctionMinPrice?.NXD ?? 0
      };
    }

    const notExpirationOffers = iNft.offers.filter((item: Offers) => item.expiration > Date.now());
    const sortedOffers: Offers[] = notExpirationOffers.sort((a: Offers, b: Offers) => b.price.NXD - a.price.NXD);
    return {
      displayContent: 'Top Bid',
      price: sortedOffers[0].price.NXD,
    }
  } else {
    return null;
  }
}

export default iNFTSlice.reducer;
