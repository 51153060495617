import { AppBar, Button, Link, TextField, Toolbar, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import Bottombar from "../components/bottombar";
import { useHistory } from 'react-router-dom';
import logo from '../logo.png';

const FormTitle = styled('div')(() => ({
  fontSize: '16px',
  color: '#FFFFFF',
  fontFamily: 'ultraRegular',
  marginBottom: '12px',
}));

const FormInput = styled(TextField)(() => ({
  marginBottom: '50px',
  '& label.Mui-focused': {
    color: '#20FB4B',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#20FB4B',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'ultraRegular',
    color: '#FFFFFF',
    borderRadius: '16px',
    '& fieldset': {
      borderColor: '#20FB4B',
    },
    '&:hover fieldset': {
      borderColor: '#20FB4B',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#20FB4B',
      borderWidth: '1px',
    },
  },
}));

const LoginButton = styled(Button)(() => ({
  width: '100%',
  height: 60,
  backgroundColor: '#20FB4B',
  color: '#000000',
  fontSize: '20px',
  fontWeight: 'bolder',
  borderRadius: '100px',
  textTransform: 'none',
  fontFamily: 'ultraRegular',
  marginTop: '68px',
  '&:hover': {
    backgroundColor: '#20FB4B',
  }
}));

const Tips = styled(Typography)(() => ({
  textAlign: 'center',
  fontSize: '12px',
  color: '#A6A6A6',
  lineHeight: '30px',
  fontFamily: 'ultraRegular',
  marginTop: '13px',
}));

const RegisterText = styled(Link)(() => ({
  color: '#A6A6A6',
  textDecorationColor: '#A6A6A6',
  cursor: 'pointer',
}));

const Login = (): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <Box sx={{
        width: '100%', height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column'
      }}>
        <AppBar position="static" sx={{ backgroundColor: '#272727' }}>
          <Toolbar>
            <img src={logo} height={40} alt="boo universe logo" />
          </Toolbar>
        </AppBar>
        <Box sx={{
          flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}>
          <Box sx={{ width: 520 }}>
            <FormTitle>
              Email
            </FormTitle>
            <FormInput fullWidth />
            <FormTitle>
              Password
            </FormTitle>
            <FormInput fullWidth type="password" />
            <LoginButton>
              Log in
            </LoginButton>
            <Tips>
              Didn't have an account?&nbsp;
              <RegisterText
                underline="always"
                onClick={() => {
                  history.replace('/register');
                }}
              >
                Register with Email
              </RegisterText>
            </Tips>
          </Box>
        </Box>
        <Bottombar />
      </Box>
    </>
  );
}

export default Login;
