import { ChainInfo } from "./chainInfo";
import { ImageType, stringToUrlType } from "./image";
import { getPrice, Price } from "./price";

interface Property {
  id: string,
  attributeType: string, // 属性类型
  attributeName: string, // 属性名称
  probability: number, // 出现概率
}

export interface TradeInfo {
  id: string, // 交易ID
  time: number, // 交易时间
  buyId: string, // 买方Id
  buyName: string, // 买方姓名
  sellId: string, // 卖方ID
  sellName: string, // 卖方姓名
  price: Price, // 价格
}

export interface Offers {
  id: string, // 要价ID
  iNftId: string, // 对应iNFT的ID
  expiration: number, // 当前价格失效时间
  fromId: string, // 出价者ID
  fromName: string, // 出价者昵称
  price: Price,
}

export enum SaleType {
  fixedPrice, // 一口价
  auction, // 拍卖
}

export const stringToSaleType = (string: string): SaleType | null => {
  let saleType = null;
  switch (string) {
    case 'fixedPrice':
      saleType = SaleType.fixedPrice;
      break;
    case 'auction':
      saleType = SaleType.auction;
      break;
    default:
      saleType = null;
      break;
  }
  return saleType;
}

export interface DisplayPrice {
  displayContent: string,
  price: number,
}

export interface INFT {
  id: string, // iNft ID
  colloctionId: string,  // 开出此iNft的盲盒ID
  createTime: number, // 创建改iNFT的时间戳
  ownerId: string, // 持有者ID
  ownerName: string, // 持有者昵称
  iNftImage: ImageType, // iNft图片
  properties: Property[], // 属性
  aiLevel: number, // AI等级
  onChainInfo: ChainInfo, // 区块链信息
  trades: TradeInfo[], // 交易信息
  saleType: SaleType | null, // 售卖类型
  fixedPrice: Price | null, // 一口价价格
  auctionMinPrice: Price | null, // 拍卖底价
  auctionDeadLine: number | null, // 拍卖截止时间
  offers: Offers[], // 出价记录
}

export const jsonToINFT = (json: any): INFT => {
  function getproperties(data: any): Property[] {
    const properties: Property[] = [];
    data.forEach((item: any) => {
      properties.push({
        id: item.id,
        attributeName: item.attributeName,
        attributeType: item.attributeType,
        probability: item.probability,
      });
    });
    return properties;
  }

  function getTradeInfo(data: any): TradeInfo[] {
    const tradeInfos: TradeInfo[] = [];
    data.forEach((item: any) => {
      tradeInfos.push({
        id: item.id,
        time: item.time,
        buyId: item.buyId,
        buyName: item.buyName,
        sellId: item.sellId,
        sellName: item.sellName,
        price: getPrice(item.price),
      });
    });
    return tradeInfos;
  }

  function getOffers(data: any): Offers[] {
    const offers: Offers[] = [];
    data.forEach((item: any) => {
      offers.push({
        id: item.id,
        fromId: item.fromId,
        fromName: item.fromName,
        expiration: item.expiration,
        iNftId: item.iNftId,
        price: getPrice(item.price),
      });
    });
    return offers;
  }

  const iNFT: INFT = {
    id: json.id, // iNft ID
    colloctionId: json.colloctionId,  // 开出此iNft的盲盒ID
    createTime: json.createTime, // 创建改iNFT的时间戳
    ownerId: json.ownerId, // 持有者ID
    ownerName: json.ownerName, // 持有者昵称
    iNftImage: {
      type: stringToUrlType(json.iNftImage.type),
      url: json.iNftImage.url,
    }, // iNft图片
    properties: getproperties(json.properties), // 属性
    aiLevel: json.aiLevel, // AI等级
    onChainInfo: {
      contractAddress: json.onChainInfo.contractAddress,
      tokenId: json.onChainInfo.tokenId,
      blockChain: json.onChainInfo.blockChain,
    }, // 区块链信息
    trades: getTradeInfo(json.trades), // 交易信息
    saleType: stringToSaleType(json.saleType), // 售卖类型
    fixedPrice: json.fixedPrice ? getPrice(json.fixedPrice) : null, // 一口价价格
    auctionMinPrice: json.auctionMinPrice ? getPrice(json.auctionMinPrice) : null, // 拍卖底价
    auctionDeadLine: json.auctionDeadLine ?? null, // 拍卖截止时间
    offers: getOffers(json.offers), // 出价记录
  };
  return iNFT;
}
