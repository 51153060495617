// import { Button, Container, Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { useSelector } from "react-redux";
import BlandBox from "../components/BlandBoxItem";
import ChainTypeButtons from "../components/chainTypeButtons";
import OutConatiner from "../components/outContainer";
import { RootState } from "../store";
import { Collection } from "../types/collection";
import DropSwiper from "../components/swiper";

const BlandTitle = styled(Typography)(() => ({
  fontFamily: 'ultraRegular',
  fontSize: 20,
}));

const NewDrops = (): JSX.Element => {
  const collections = useSelector<RootState, Collection[]>((state) => state.collection.list);

  return (<>
    <Box>
      <Box sx={{ width: '100%', height: 320, marginTop: '16px' }}>
        <DropSwiper />
      </Box>
      <OutConatiner>
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '60px', marginBottom: '30px', alignItems: 'end' }}
        >
          <BlandTitle>
            Bland Box
          </BlandTitle>
          <ChainTypeButtons />
        </Box>
        <Box
          sx={{ display: 'flex', flexWrap: 'wrap' }}
        >
          {
            collections.map((item: Collection) => (
              <BlandBox key={item.id} collection={item} />
            ))
          }
        </Box>
      </OutConatiner>
    </Box>
  </>)
};

export default NewDrops;
