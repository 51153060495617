export interface Price {
  NXD: number,
  BTC: number,
  ETH: number,
}

export const getPrice = (data: any): Price => {
  return {
    NXD: data.NXD,
    BTC: data.BTC,
    ETH: data.ETH,
  };
}
