import { Button, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper.min.css';

import Banner1 from '../assets/banner1.png';
import Banner2 from '../assets/banner2.png';
import Banner3 from '../assets/banner3.png';
import NewImage from '../assets/new_banner.png'
import SaleoutImage from '../assets/saleout_banner.png'
import NXDImage from '../assets/NXD_selected.png';

const SwiperButton = styled(Button)(() => ({
  width: 130,
  height: 31,
  backgroundColor: '#20FB4B',
  color: '#000000',
  fontSize: '12px',
  borderRadius: '100px',
  textTransform: 'none',
  fontFamily: 'ultraRegular',
  '&:hover': {
    backgroundColor: '#20FB4B',
  }
}));

const DropSwiper = (): JSX.Element => {
  return (
    <>
      <Box
        sx={{ width: '100%', height: 320 }}
      >
        <Swiper
          slidesPerView={3}
          spaceBetween={20}
          centeredSlides
          loop
          autoplay={{
            delay: 5000,
          }}
        >
          <SwiperSlide>
            <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
              <img src={Banner1} alt="banner" style={{ width: '100%', height: '100%' }} />
              <Box
                sx={{ position: 'absolute', left: '4em', top: '5em' }}
              >
                <Box
                  sx={{
                    borderRadius: '100px',
                    backgroundColor: '#FFFFFF',
                    padding: '5px 0',
                    width: 156,
                    color: '#000000',
                    fontWeight: 'bolder',
                    fontSize: 12,
                    marginBottom: '27px',
                    textAlign: 'center',
                  }}
                >
                  Top Collection
                </Box>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '23px',
                    lineHeight: '43px',
                    height: 43,
                    display: 'inline-block',
                    fontFamily: 'ultraBold',
                    fontWeight: 'bolder',
                    marginBottom: '6px',
                  }}
                >
                  Coming Soon
                </Typography>
                <Typography
                  sx={{
                    color: '#B7B7B7',
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontFamily: 'ultraRegular',
                    marginBottom: '12px',
                  }}
                >
                  &nbsp;
                </Typography>
                <SwiperButton>
                  <img
                    style={{ marginTop: '-1px' }}
                    src={NXDImage}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <span style={{ fontSize: '14px', fontWeight: 'bolder' }}>
                    170
                  </span>
                  &nbsp;
                  NXD
                </SwiperButton>
              </Box>
              <Box
                sx={{
                  // width: 198,
                  height: '7em',
                  position: 'absolute',
                  left: '0',
                  top: '0',
                }}
              >
                <img src={NewImage} alt="new banner" style={{ height: '100%', display: 'block' }} />
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
              <img src={Banner2} alt="banner" style={{ width: '100%', height: '100%' }} />
              <Box
                sx={{ position: 'absolute', left: '4em', top: '5em' }}
              >
                <Box
                  sx={{
                    borderRadius: '100px',
                    backgroundColor: '#FFFFFF',
                    padding: '5px 0',
                    width: 156,
                    color: '#000000',
                    fontWeight: 'bolder',
                    fontSize: 12,
                    marginBottom: '27px',
                    textAlign: 'center',
                  }}
                >
                  Most Favorites
                </Box>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '23px',
                    lineHeight: '43px',
                    height: 43,
                    display: 'inline-block',
                    fontFamily: 'ultraBold',
                    fontWeight: 'bolder',
                    marginBottom: '6px',
                  }}
                >
                  Very Rare
                </Typography>
                <Typography
                  sx={{
                    color: '#B7B7B7',
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontFamily: 'ultraRegular',
                    marginBottom: '12px',
                  }}
                >
                  &nbsp;
                </Typography>
                <SwiperButton>
                  <img
                    style={{ marginTop: '-1px' }}
                    src={NXDImage}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <span style={{ fontSize: '14px', fontWeight: 'bolder' }}>
                    120
                  </span>
                  &nbsp;
                  NXD
                </SwiperButton>
              </Box>
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
              <img src={Banner3} alt="banner" style={{ width: '100%', height: '100%' }} />
              <Box
                sx={{ position: 'absolute', left: '4em', top: '5em' }}
              >
                <Box
                  sx={{
                    borderRadius: '100px',
                    backgroundColor: '#FFFFFF',
                    padding: '5px 0',
                    width: 156,
                    color: '#000000',
                    fontWeight: 'bolder',
                    fontSize: 12,
                    marginBottom: '27px',
                    textAlign: 'center',
                  }}
                >
                  Top Collection
                </Box>
                <Typography
                  sx={{
                    color: '#FFFFFF',
                    fontSize: '23px',
                    lineHeight: '43px',
                    height: 43,
                    display: 'inline-block',
                    fontFamily: 'ultraBold',
                    fontWeight: 'bolder',
                    marginBottom: '6px',
                  }}
                >
                  Super Rare
                </Typography>
                <Typography
                  sx={{
                    color: '#B7B7B7',
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontFamily: 'ultraRegular',
                    marginBottom: '12px',
                  }}
                >
                  Contains 5 Digital Goods
                </Typography>
                <SwiperButton>
                  <img
                    style={{ marginTop: '-1px' }}
                    src={NXDImage}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <span style={{ fontSize: '14px', fontWeight: 'bolder' }}>
                    110
                  </span>
                  &nbsp;
                  NXD
                </SwiperButton>
              </Box>
              <Box
                sx={{
                  // width: 198,
                  height: '7em',
                  position: 'absolute',
                  left: '0',
                  top: '0',
                }}
              >
                <img src={SaleoutImage} alt="new banner" style={{ height: '100%', display: 'block' }} />
              </Box>
            </Box>
          </SwiperSlide>
        </Swiper>
      </Box>
    </>
  );
}

export default DropSwiper;
