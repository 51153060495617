import { Box, styled } from "@mui/system";

const TermsAndPrivacy = styled('span')(() => ({
  display: 'inline-block',
  width: 194,
  fontSize: '18px',
  fontFamily: 'ultraRegular',
  marginLeft: '200px',
  color: '#A6A6A6',
  cursor: 'pointer',
}));

const SocialPlatform = styled('span')(() => ({
  display: 'inline-block',
  fontSize: '18px',
  fontFamily: 'ultraRegular',
  color: '#A6A6A6',
  cursor: 'pointer',
}));

const CopyRight = styled('span')(() => ({
  marginRight: '260px',
  display: 'inline-block',
  fontSize: '18px',
  fontFamily: 'ultraRegular',
  color: '#A6A6A6',
  cursor: 'pointer',
}));

const Bottombar = (): JSX.Element => (
  <Box sx={{ width: '100%', height: 192 }}>
    <Box sx={{
      width: '100%', height: 48, backgroundColor: 'rgba(33, 33, 39, 1)', display: 'flex', alignItems: 'center',
    }}>
      <TermsAndPrivacy>
        Terms
      </TermsAndPrivacy>
      <SocialPlatform>
        Twitter
      </SocialPlatform>
      <Box sx={{ flexGrow: 1 }}></Box>
      <CopyRight>
        2021 Boo Universe
      </CopyRight>
    </Box>
    <Box sx={{
      width: '100%', height: 48, backgroundColor: 'rgba(33, 33, 39, 0.7)', display: 'flex', alignItems: 'center',
    }}>
      <TermsAndPrivacy>
        Privacy
      </TermsAndPrivacy>
      <SocialPlatform>
        Telegram
      </SocialPlatform>
    </Box>
    <Box sx={{
      width: '100%', height: 48, backgroundColor: 'rgba(33, 33, 39, 0.5)', display: 'flex', alignItems: 'center',
    }}>
      <TermsAndPrivacy />
      <SocialPlatform>
        Discord
      </SocialPlatform>
    </Box>
    <Box sx={{
      width: '100%', height: 48, backgroundColor: 'rgba(33, 33, 39, 0.3)', display: 'flex', alignItems: 'center',
    }}>

    </Box>
  </Box>
);

export default Bottombar;
