import { INFT, jsonToINFT } from "../types/iNft";
import { httpGet } from "../utils/axios";

export const getINFTs = async (): Promise<INFT[]> => {
  try {
    const data: any = await httpGet('/datas/iNFTs.json', {}, false);
    const iNFTs: INFT[] = [];
    data.forEach((item: any) => {
      const iNFT: INFT = jsonToINFT(item);
      iNFTs.push(iNFT);
    });
    return iNFTs;
  } catch (error) {
    throw error;
  }
}
