// import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
  // Redirect,
} from "react-router-dom";

// import MarketPlace from './pages/marketplace';
import Home from './pages/home';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import PrimarySearchAppBar from './components/appbar';
// import Ranking from './pages/ranking';
import { CircularProgress, Modal } from '@mui/material';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { RootState } from './store';
// import { closeLoading, openLoading } from './features/systemSlice';
// import { Collection } from './types/collection';
// import { INFT } from './types/iNft';
// import { getINFTs } from './services/iNFT';
// import { getCollections } from './services/collections';
// import { addCollectionsData } from './features/collectionsSlice';
// import { addINFTs } from './features/iNFTSlice';
// import { sleep } from './utils/util';
import Login from './pages/login';
import Register from './pages/register';

import SwiperCore, { Autoplay } from 'swiper';
// import CollectionDetail from './pages/collectionDetail';

const CenterLoading = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginLeft: '-20px',
  marginTop: '-20px',
  zIndex: 3002,
  color: 'white'
}));

function App() {
  SwiperCore.use([Autoplay]);

  const loadingStatus = useSelector<RootState, boolean>((state) => state.system.isLoading);

  return (
    <>
      {/* <Router>
        <PrimarySearchAppBar />
        <div>
          <Switch>
            <Route path="/marketPlace">
              <MarketPlace />
            </Route>
            <Route path="/ranking">
              <Ranking />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router> */}
      <Router>
        <Switch>
          <Route path="/login">
            <div>
              <Login />
            </div>
          </Route>
          <Route path="/register">
            <div>
              <Register />
            </div>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
      <Modal
        open={loadingStatus}
        disableEscapeKeyDown
        disableAutoFocus
        disableEnforceFocus
        disableRestoreFocus
      >
        <CenterLoading />
      </Modal>
    </>
  );
}

export default App;
