import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Collection } from '../types/collection';
import ArrowBack from '@mui/icons-material/ArrowBack';
import OutContainer from '../components/outContainer';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import _ from 'lodash';

const CollectionDetail = (): JSX.Element => {
  const { id: collectionId } = useParams();
  const history = useHistory();
  const CollectionList = useSelector<RootState, Collection[]>((state) => state.collection.list);
  const selectedCollection: Collection | undefined = _.find(CollectionList, (o: Collection) => o.id === collectionId);

  const [collection, setCollection] = useState<Collection | undefined>(selectedCollection);

  const handleBack = () => history.goBack();

  useEffect(() => {
    const changedCollection: Collection | undefined = _.find(CollectionList, (o: Collection) => o.id === collectionId);
    setCollection(changedCollection);
  }, [CollectionList]);


  return (
    <>
      <Box>
        <OutContainer>
          <Box
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={handleBack}
          >
            <ArrowBack sx={{ fontSize: 28 }} />
            <Typography sx={{ fontSize: 14, marginLeft: '12px' }}>
              New Drops
            </Typography>
          </Box>
          {
            collection && (
              <Box
                sx={{ display: 'flex', }}
              >
                <Box
                  sx={{ width: 588, height: 588, position: 'relative' }}
                >
                  <img src={collection.blandBoxImage.url} alt="collection image" style={{ width: '100%', height: '100%', display: 'block' }} />
                </Box>
                <Box sx={{ width: 20 }} />
                <Box sx={{ flexGrow: 1 }}>
                  <Typography>
                    {collection.creatorName}
                  </Typography>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Box sx={{ marginRight: '35px' }}>
                      <Typography sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}>
                        {collection.name}
                      </Typography>
                    </Box>
                    <Box sx={{
                      width: 157,
                      height: 31,
                      backgroundColor: '#FFFFFF',
                      color: '#000000',
                      fontFamily: 'ultraBold',
                      fontSize: 16,
                      borderRadius: '130px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                      Bland Box
                    </Box>
                  </Box>
                </Box>
              </Box>
            )
          }
        </OutContainer>
      </Box>
    </>
  );
}

export default CollectionDetail;
