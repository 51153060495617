import axios, {
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { closeLoading, getLoadingStatus, openLoading } from '../features/systemSlice';
import { store } from '../store';

axios.defaults.timeout = 1000000;

// Request拦截器
axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.data = JSON.stringify(config.data);

  // TODO: 在此做添加token等操作

  config.headers = {
    'Content-Type': 'application/json',
  };
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Response拦截器
axios.interceptors.response.use((response: AxiosResponse) => {
  msg(response.status);
  if (response.status === 401) {
    // 用户Token无效
    return Promise.reject('登录状态过期');
  }
  if (response.status !== 200) {
    return Promise.reject('接口错误');
  }
  return response;
}, (error) => {
  if (axios.isCancel(error)) {
    throw new axios.Cancel('cancel request')
  } else {
    msg(69000, '网络请求失败，请重试！');
  }
  return Promise.reject(error);
});

export const httpGet = async (url: string, params: object = {}, needLoading: boolean = true): Promise<AxiosResponse<any, any>> => {
  const isLoadingBefore = getLoadingStatus(store.getState());

  if (needLoading && !isLoadingBefore) {
    store.dispatch(openLoading());
  }
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params,
    }).then((response: AxiosResponse) => {
      landing(url, params, response.data);
      resolve(response.data);
    }).catch(error => {
      reject(error);
    }).finally(() => {
      const isLoadingAfter = getLoadingStatus(store.getState());
      if (needLoading && isLoadingAfter) {
        setTimeout(() => {
          store.dispatch(closeLoading());
        }, Math.floor(Math.random() * 5) * 1000);
      }
    });
  });
}

export const httpPost = async (url: string, data: object = {}, needLoading: boolean = true): Promise<AxiosResponse<any, any>> => {
  const isLoadingBefore = getLoadingStatus(store.getState());

  if (needLoading && !isLoadingBefore) {
    store.dispatch(openLoading());
  }
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then((response: AxiosResponse) => {
        landing(url, data, response.data);
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      })
      .finally(() => {
        const isLoadingAfter = getLoadingStatus(store.getState());
        if (needLoading && isLoadingAfter) {
          setTimeout(() => {
            store.dispatch(closeLoading());
          }, Math.floor(Math.random() * 5) * 1000);
        }
      });
  });
}

const landing = (url: string, params: any, data: any) => {
  console.log('%curl: ', 'color: #99CCCC; background: Aquamarine; font-size: 18px;', url);
  console.log('%cdata: ', 'color: #99CCFF; background: Aquamarine; font-size: 18px;', data);
  console.log('%cparams: ', 'color: #9999CC; background: Aquamarine; font-size: 18px;', params);
}

const msg = (errorCode: number, context: string = '') => {
  switch (errorCode) {
    case 400:
      alert('接口错误');
      break;
    case 401:
      alert('未授权，请登录！');
      break;
    case 403:
      alert('拒绝访问');
      break;
    case 404:
      alert('请求地址不存在');
      break;
    case 408:
      alert('请求超时');
      break;
    case 500:
      alert('服务器内部错误');
      break;
    case 501:
      alert('服务未实现');
      break;
    case 502:
      alert('网关错误');
      break;
    case 503:
      alert('服务不可用');
      break;
    case 504:
      alert('网关超时');
      break;
    case 505:
      alert('HTTP版本不受支持');
      break;
    case 69000:
      alert(context);
      break;
    default:
      break;
  }
}
