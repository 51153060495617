import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom'
import PrimarySearchAppBar from '../components/appbar';
import { addCollectionsData } from '../features/collectionsSlice';
import { addINFTs } from '../features/iNFTSlice';
import { closeLoading, openLoading } from '../features/systemSlice';
import { getCollections } from '../services/collections';
import { getINFTs } from '../services/iNFT';
import { Collection } from '../types/collection';
import { INFT } from '../types/iNft';
import { sleep } from '../utils/util';
import CollectionDetail from './collectionDetail';
import MarketPlace from './marketplace';
import NewDrops from "./newdrops";
import Ranking from './ranking';

const Home = (): JSX.Element => {
  const dispatch = useDispatch();

  const initData = async () => {
    dispatch(openLoading());
    const collections: Collection[] = await getCollections();
    dispatch(addCollectionsData(collections));
    const iNFTs: INFT[] = await getINFTs();
    dispatch(addINFTs(iNFTs));
    await sleep(Math.floor(Math.random() * 3) * 1000);
    dispatch(closeLoading());
  }

  useEffect(() => {
    initData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PrimarySearchAppBar />
      <div>
        <Switch>
          <Route exact path="/">
            <NewDrops />
          </Route>
          <Route path="/marketPlace">
            <MarketPlace />
          </Route>
          <Route path="/ranking">
            <Ranking />
          </Route>
          <Route path="/collection/:id" component={CollectionDetail} />
        </Switch>
      </div>
    </>
  );
}

export default Home;
