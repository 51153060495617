import { Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Collection } from '../types/collection';

interface TopCollectionsTableProps {
  collections: Collection[],
}

interface TopCreatorsTableProps {
  creators: [],
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: 'ultraRegular',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#151515',
    color: theme.palette.text.secondary,
    fontSize: 14,
    border: 'none',
    borderBottom: '1px solid #979797',
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#151515',
    fontSize: 14,
    borderBottom: '1px solid #979797',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#151515',
  borderBottom: '1px solid #979797',
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 'none',
  },
}));

export const TopCollectionsTable = ({ collections }: TopCollectionsTableProps): JSX.Element => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>Collections</StyledTableCell>
              <StyledTableCell>Volume</StyledTableCell>
              <StyledTableCell>Floor Price</StyledTableCell>
              <StyledTableCell>Owners</StyledTableCell>
              <StyledTableCell>Items</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              collections.map((row: Collection, index: number) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', }}>
                      {index + 1}
                      <Box sx={{ width: 48, height: 48, borderRadius: '100px', overflow: 'hidden', marginLeft: '20px', marginRight: '12px' }}>
                        <img src={row.avatar} alt="collection avatar" width={48} height={48} />
                      </Box>
                      {row.name}
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.volume}
                  </StyledTableCell>
                  <StyledTableCell>
                    $ {row.floorPrice.NXD}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.owners.length}
                  </StyledTableCell>
                  <StyledTableCell>
                    {row.totalCount - row.lastCount}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export const TopCreatorsTable = ({ creators }: TopCreatorsTableProps): JSX.Element => {
  return (
    <>

    </>
  );
}
