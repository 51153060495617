import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, styled } from "@mui/system";
import { Button, ButtonProps, InputBase, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Collection } from '../types/collection';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import FilterCollectionItem from './filterCollectionItem';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: 'none',
  '&:before': {
    display: 'none',
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '32px', color: '#FFFFFF' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: '#151515',
  flexDirection: 'row',
  borderBottom: '1px solid #979797',
  color: '#FFFFFF',
  padding: '0',
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid #f66',
  border: 'none',
  backgroundColor: '#151515',
  color: '#FFFFFF',
  paddingLeft: '0',
  paddingRight: '0',
}));

const StatusButton = styled(Button)<ButtonProps>(() => ({
  width: 152,
  height: 40,
  border: '1.5px solid #20FB4B',
  backgroundColor: '#151515',
  color: '#20FB4B',
  borderRadius: '21px',
  marginBottom: '10px',
  fontSize: '12px',
  fontFamily: 'ultraRegular',
  textTransform: 'none',
}));

const ApplyButton = styled(Button)<ButtonProps>(() => ({
  width: '100%',
  height: 40,
  border: '1.5px solid #20FB4B',
  backgroundColor: '#151515',
  color: '#20FB4B',
  borderRadius: '21px',
  marginBottom: '10px',
  fontSize: '14px',
  fontFamily: 'ultraRegular',
  textTransform: 'none',
}));

const NumberInputWrapper = styled('div')(() => ({
  width: 126,
  height: 40,
  border: '1.5px solid #20FB4B',
  borderRadius: '21px',
  backgroundColor: '#151515',
}));

const NumberInput = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',
    color: '#FFFFFF',
    fontFamily: 'ultraRegular',
    lineHeight: '40px',
    padding: theme.spacing(1.5, 0, 0, 0),
  },
}));

const FilterInputWrapper = styled('div')(() => ({
  width: '100%',
  height: 40,
  border: '1.5px solid #20FB4B',
  borderRadius: '21px',
  backgroundColor: '#151515',
  marginBottom: '18px',
  position: 'relative',
}));

const FilterInputIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FilterInput = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    width: '100%',
    fontSize: '12px',
    color: '#FFFFFF',
    fontFamily: 'ultraRegular',
    lineHeight: '40px',
    padding: theme.spacing(1.5, 0, 0, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`
  },
}));



const MarketplaceSide = (): JSX.Element => {
  const CollectioList = useSelector<RootState, Collection[]>((state) => state.collection.list);

  return (
    <>
      <Box sx={{ width: '318px' }}>
        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography sx={{ fontFamily: 'ultraRegular', fontSize: 16, }}>
              Status
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <StatusButton>
                Buy Now
              </StatusButton>
              <StatusButton>
                Auction
              </StatusButton>
              <StatusButton>
                Has Offers
              </StatusButton>
              <StatusButton>
                New
              </StatusButton>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography sx={{ fontFamily: 'ultraRegular', fontSize: 16, }}>
              Price
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <NumberInputWrapper>
                <NumberInput placeholder="Min" />
              </NumberInputWrapper>
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: 'center',
                  fontSize: '14px',
                  fontFamily: 'ultraRegular',
                  color: '#FFFFFF',
                }}
              >
                to
              </Box>
              <NumberInputWrapper>
                <NumberInput placeholder="Max" />
              </NumberInputWrapper>
            </Box>
            <ApplyButton>
              Apply
            </ApplyButton>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography sx={{ fontFamily: 'ultraRegular', fontSize: 16, }}>
              Collection
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FilterInputWrapper>
              <FilterInputIconWrapper>
                <SearchIcon />
              </FilterInputIconWrapper>
              <FilterInput
                placeholder="Filter"
                inputProps={{ 'aria-label': 'filter' }}
              />
            </FilterInputWrapper>
            <Box>
              {
                CollectioList.map((item: Collection) => (
                  <FilterCollectionItem collection={item} />
                ))
              }
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

export default MarketplaceSide;
