import { createSlice } from '@reduxjs/toolkit'

export const localeSlice = createSlice({
  name: 'locale',
  initialState: {
    value: 'en',
  },
  reducers: {
    setLocale: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setLocale } = localeSlice.actions

export default localeSlice.reducer