import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Collection } from '../types/collection';

interface Props {
  collection: Collection,
}

const ImageWrapper = styled('div')(() => ({
  width: 48,
  height: 48,
  borderRadius: '100%',
  overflow: 'hidden',
  marginRight: '13px',
}));

const Image = styled('img')(() => ({
  width: 48,
  height: 48,
}));

const CollectionName = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#FFFFFF',
  fontFamily: 'ultraRegular',
}));

const FilterCollectionItem = ({ collection }: Props): JSX.Element => {
  return (
    <>
      <Box sx={{
        width: '100%',
        height: 60,
        display: 'flex',
        alignItems: 'center',
      }}>
        <ImageWrapper>
          <Image src={collection.avatar} />
        </ImageWrapper>
        <CollectionName>
          {collection.name}
        </CollectionName>
      </Box>
    </>
  );
}

export default FilterCollectionItem;
