import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingOuter = styled('div')(({ theme }) => ({
    position: 'fixed',
    backgroundColor: '#fff',
    margin: 0,
    padding: 0,
    right: 0,
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 3001,
  }));

const CenterLoading = styled(CircularProgress)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginLeft: '-20px',
    marginTop: '-20px',
    zIndex: 3002,
  }));

const PageLoading = (): JSX.Element =>(<>
        <LoadingOuter>
            <CenterLoading/>
        </LoadingOuter>
    </>);

export default PageLoading;
