import { configureStore } from '@reduxjs/toolkit'
import localeReducer from './features/localSlice'
import collectionReducer from './features/collectionsSlice'
import iNFTReducer from './features/iNFTSlice';
import systemReducer from './features/systemSlice';

export const store = configureStore({
  reducer: {
    locale: localeReducer,
    collection: collectionReducer,
    iNFT: iNFTReducer,
    system: systemReducer,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
