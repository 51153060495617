import { Container, Grid, InputBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';

import OutContainer from "../components/outContainer";
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { INFT } from '../types/iNft';
import MarketplaceItem from '../components/marketplaceItem';
import ChainTypeButtons from '../components/chainTypeButtons';
import MarketplaceSide from '../components/marketplaceSide';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '16px',
  backgroundColor: '#151515',
  border: '1px solid #FFFFFF',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: 0,
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.common.white,
  width: '100%',
  height: 58,
  fontSize: 14,
  fontFamily: 'ultraRegular',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
}));

const MarketPlace = (): JSX.Element => {
  const iNFTs = useSelector<RootState, INFT[]>((state) => state.iNFT.list);

  return (
    <>
      <OutContainer>
        <Box>
          <Grid container direction="column" alignItems="flex-start">
            <Grid container alignItems="center" direction="row">
              <Box sx={{ flexGrow: 1 }}>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon style={{ color: 'white' }}>
                      <SearchIcon />
                    </SearchIcon>
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Search by creator and title"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
              </Box>
              <ChainTypeButtons />
            </Grid>
            <Box style={{ height: '8px' }} />
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Container
                fixed
                style={{ width: '318px', padding: 0, marginRight: '30px' }}
              >
                <MarketplaceSide />
              </Container>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                <div style={{ fontSize: '14px', fontFamily: 'ultraRegular', color: '#A6A6A6', marginBottom: '16px', paddingTop: '30px' }}>
                  {iNFTs.length} result
                </div>
                <Grid container>
                  {
                    iNFTs.map((iNFT: INFT) => (
                      <Grid item xs={4} key={iNFT.id}>
                        <MarketplaceItem
                          iNFT={iNFT}
                        />
                      </Grid>
                    ))
                  }
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </OutContainer>
    </>
  );
};

export default MarketPlace;
