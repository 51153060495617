import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { BlockChainType } from "../types/blockChain";

import NXDImage from "../assets/NXD.png";
import NXDSelectedImage from "../assets/NXD_selected.png";
import BTCImage from "../assets/BTC.png";
import BTCSelectedImage from "../assets/BTC_selected.png";
import ETHImage from "../assets/ETH.png";
import ETHSelectedImage from "../assets/ETH_selected.png";
import { changeChainType } from "../features/systemSlice";

const ChainTypeButtons = (): JSX.Element => {
  const dispatch = useDispatch();
  const chainType: BlockChainType = useSelector<RootState, BlockChainType>((state) => state.system.chainType);

  const changeBlockChainType = (type: BlockChainType) => {
    if (chainType !== type) {
      dispatch(changeChainType(type));
    }
  };

  return (
    <Box sx={{ width: 156, height: 50 }}>
      <Grid container style={{ height: '50px', cursor: 'pointer' }}>
        <Grid
          container
          item
          xs={4}
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: chainType === BlockChainType.NXD ? '#FFFFFF' : '#151515', border: '1px solid #FFFFFF' }}
        >
          <img
            src={chainType === BlockChainType.NXD ? NXDSelectedImage : NXDImage}
            alt=""
            style={{ width: '100%', height: '100%' }}
            onClick={() => {
              changeBlockChainType(BlockChainType.NXD)
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: chainType === BlockChainType.BTC ? '#FFFFFF' : '#151515', border: '1px solid #FFFFFF' }}
        >
          <img
            src={chainType === BlockChainType.BTC ? BTCSelectedImage : BTCImage}
            alt=""
            style={{ width: '100%', height: '100%' }}
            onClick={() => {
              changeBlockChainType(BlockChainType.BTC)
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={4}
          justifyContent="center"
          alignItems="center"
          style={{ backgroundColor: chainType === BlockChainType.ETH ? '#FFFFFF' : '#151515', border: '1px solid #FFFFFF' }}
        >
          <img
            src={chainType === BlockChainType.ETH ? ETHSelectedImage : ETHImage}
            alt=""
            style={{ width: '100%', height: '100%' }}
            onClick={() => {
              changeBlockChainType(BlockChainType.ETH)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ChainTypeButtons;
